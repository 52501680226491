import { JSONSchema7 } from 'json-schema';

import tuple from './tuple';

export enum ModuleMode {
  ON = 'ON',
  OFF = 'OFF',
}

export enum VoucherRequirements {
  DISABLED = 'disabled',
  OPTIONAL = 'optional',
  REQUIRED = 'required',
}
export const FIELD_TYPES = tuple('string', 'password', 'boolean', 'number', 'color', 'file', 'array', 'object');
export const FILE_TYPES = tuple('file', 'image', 'video', 'audio');
export const FILE_SCOPES = tuple('tenant', 'public', 'user');

export type FieldType = typeof FIELD_TYPES[number];
export type FileType = typeof FILE_TYPES[number];
export type FileScope = typeof FILE_SCOPES[number];

export interface IConfigEntry extends JSONSchema7 {
  _id?: string;
  client?: boolean; // if the config key can be read by a client app (over graphql) - defaults to true
  clientProps?: string[]; // which props may be read by a client app (use this instead of "client" for object types)
  field?: IConfigField;
  hide?: boolean; // if the config item should be hidden in admin
  items?: IConfigEntry & {
    properties?: {
      [key: string]: IConfigEntry;
    };
  };
  properties?: {
    [key: string]: IConfigEntry;
  };
  anyOf?: IConfigEntry[];
  oneOf?: IConfigEntry[];
}

export type IConfigField = {
  type?: FieldType;
  elementsName?: string; // for array fields, the "name" of the elements which are container (used for displaying "Add Element Names" instead of "Add field" in admin dashboard)
  fileType?: FileType; // for "file": file type (default = file)
  fileScope?: FileScope; // for "file" - scope of uploaded file - set to 'public' to make the uploaded file available for non-logged in users (default = tenant)
};

type SchemaPartNeededToCast = {
  type?: string | string[];
  items?: SchemaPartNeededToCast;
  properties?:
    | Record<string, SchemaPartNeededToCast>
    | { key: string; schema: SchemaPartNeededToCast }[];
};

function findPropertySchema(
  schema: SchemaPartNeededToCast,
  key: string
): SchemaPartNeededToCast | undefined {
  if (Array.isArray(schema.properties)) {
    return schema.properties.find((property) => property.key === key)?.schema;
  }
  return schema.properties && schema.properties[key];
}

/**
 * Cast string value according to its type. (or array of types, as in json schema)
 *
 * This function is also used in app-client.
 */
export function castValue(v: any, schema: SchemaPartNeededToCast) {
  const { type } = schema;
  const types = Array.isArray(type) ? type : [type];

  if (v === null || v === undefined) {
    // special treatment of null value
    return castNull(type);
  }

  if (typeof v !== 'string') return v; // only strings will be casted

  return types.reduce((v: string, type) => {
    switch (type) {
      case 'number':
        if (!isNaN(+v)) {
          return +v;
        }
        break;

      case 'boolean':
        return v === 'true' || v === 'on' || v === 'enabled' || v === '1';

      case 'null':
        if (v === '') {
          return null;
        }
        break;

      case 'array':
        try {
          const castedValue = JSON.parse(v);
          return schema.items
            ? castedValue.map((item: any) => castValue(item, schema.items!))
            : castedValue;
        } catch (e) {
          console.log('Error when casting value of type array', e); // eslint-disable-line no-console
        }
        break;
      case 'object':
        try {
          const castedValue = JSON.parse(v);

          const castedObject: Record<string, any> = Object.keys(castedValue).reduce(
            (castedObject: Record<string, any>, key) => {
              const propertySchema = findPropertySchema(schema, key);
              return {
                ...castedObject,
                [key]: propertySchema
                  ? castValue(castedValue[key], propertySchema)
                  : castedValue[key],
              };
            },
            {}
          );
          return castedObject;
        } catch (e) {
          console.log('Error when casting value of type object', e); // eslint-disable-line no-console
        }
        break;

      // do nothing by default, string is the default type also for other custom types
    }

    return v;
  }, v);
}

/**
 * in json schema null has to be specified explicitly as allowed type.
 * This casting of null value is used to not have a null value served over gql
 * for either ‘value’ or ‘default’ which would break saving a config, if null is not allowed for a field.
 * Instead it resolves default empty value based on the type.
 * @param type
 */
export function castNull(type: any) {
  const types = Array.isArray(type) ? type : [type];
  if (types.includes('null')) return null;
  if (types.includes('number')) return 0;
  if (types.includes('boolean')) return false;
  if (types.includes('array')) return [];
  if (types.includes('object')) return {};
  return '';
}

export function serializeValue(v: any) {
  if (v === undefined || v === null) {
    return null;
  }
  if (v !== Object(v)) {
    // if primitive
    return v.toString();
  }

  return JSON.stringify(v);
}
