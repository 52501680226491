import { isCypress } from '~common/cypress';

import { downloadConfig } from 'domains/config/redux';

export const configureCypress = () => {
  // expose helper functions to cypress
  window.ovosCypress = {
    configureCypress,
    downloadConfig: () => {
      return new Promise(resolve => {
        const waitAppStore = setInterval(() => {
          if (!window.appStore) return;

          clearInterval(waitAppStore);

          resolve(window.appStore.dispatch(downloadConfig()));
        }, 100);
      });
    },
    queryClient: () => import('lib/graphql').then(gql => gql.queryClient),
  };
};

if (isCypress()) {
  configureCypress();
}
