/**
 * This monkey-patches console.warn in order to suppress warnings about componentWillReceiveProps deprecation.
 * antd did not yet migrate to the new methods and this would clutter our logs
 * also react-apollo < v3 still uses old methods
 *
 * @todo remove console.warn monkeypatch once antd migrated to new lifecycle methods
 * https://github.com/ant-design/ant-design/issues/9792
 *
 * Maybe we can also remove it after switching over to greenkeeper branch
 */
export const monkeyPatchReactWarnings = () => {
  const doWarn = window.console.warn;
  window.console.warn = (...args) => {
    if (
      typeof args[0] !== 'string' ||
      !args[0].match(/^Warning: (componentWillReceiveProps|componentWillUpdate|componentDidMount|componentWillMount) has been renamed/)
    ) {
      doWarn(...args);
    } else {
      const matches = args[0].match(/Please update the following components: (.*)/);
      // (.*) is '%s' now and component names are passed in the second argument now,
      // and before some react version there was probably only one string
      doWarn(...[`React Lifecycle Warning - ${matches && matches[0]}`, args[1]].filter(Boolean));
    }
  };
};
