import 'lazysizes';

document.addEventListener('lazybeforeunveil', (e) => {
  // @ts-ignore getAttribute on event target
  const bg = e.target.getAttribute('data-bg');

  if (bg) {
    // @ts-ignore style on event target
    e.target.style.backgroundImage = `url(${bg})`;
  }
});
