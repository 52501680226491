import produce from 'immer';
import { castValue } from '~common/configTypes';
import { TenantConfig } from '~common/tenantConfig';

import { DownloadConfigQuery } from '~common/app.graphql';

import downloadConfigQuery from './graphql/downloadConfigQuery.graphql';

export interface ConfigState extends Partial<TenantConfig> {
  _pages?: DownloadConfigQuery['pages']; // array of additional custom menu items
  _loaded?: number; // specifies when config has been loaded
}

interface ConfigAction {
  type: string;
  payload: { data: DownloadConfigQuery };
}

const defaultState: ConfigState = window.TENANT_CONFIG
  ? {
    ...window.TENANT_CONFIG,
    _pages: [], // pages are not preloaded and will be loaded on app start
    _loaded: window.TENANT_CONFIG_FETCH_TIME || undefined,
  }
  : {};

export const DOWNLOAD_TENANT_CONFIG = 'DOWNLOAD_TENANT_CONFIG';
export const SET_TENANT_CONFIG = 'SET_TENANT_CONFIG';

export const downloadConfig = () => ({
  type: DOWNLOAD_TENANT_CONFIG,
  meta: {
    offline: {
      name: DOWNLOAD_TENANT_CONFIG,
      effect: {
        type: 'graphql',
        query: downloadConfigQuery,
        operationName: 'downloadConfig',
      },
      commit: { type: SET_TENANT_CONFIG },
    },
  },
});

const setTenantConfig = ({ items = [], pages = undefined }) =>
  ({ type: SET_TENANT_CONFIG, payload: { data: { items, pages } } });

const configReducer = produce((draft: ConfigState, { type, payload }: ConfigAction) => {
  switch (type) {
    case SET_TENANT_CONFIG:
      draft._loaded = Date.now();

      if (payload.data.items) {
        for (const item of payload.data.items) {
          draft[item.key] = castValue(item.value, item.schema);
        }
      }

      if (payload.data.pages) {
        draft._pages = payload.data.pages;
      }

      break;
  }
}, defaultState);

export const reducerKey = 'config';
export const reducer = configReducer;
export const actions = {
  downloadConfig,
  setTenantConfig,
};
